import { FC } from 'react';

// Styles
import "./Contact.scss";

// Components
import Page from '../../components/Page';

const Contact: FC = () => {
    return (
        <Page className='contact'>

        </Page>
    );
};

export default Contact;