import { FC } from 'react';

// Styles
import './Tags.scss';

// Guard
interface TagsProps {
    color: string;
    type: string;
    role: string;
    duration: string;
};

// Components

const Tags: FC<TagsProps> = ({ color, type, role, duration }) => {
    return (
        <div className="tag">
            <div className="tag__card">
                <svg 
                    className="tag__symbol tag__symbol--type" 
                    viewBox="0 0 512 512"
                    style={{fill: color}}>
                    <path d="m162 514h188c40.804 0 74-33.196 74-74v-366c0-40.804-33.196-74-74-74h-188c-40.804 0-74 33.196-74 74v366c0 40.804 33.196 74 74 74zm-26-440c0-14.336 11.664-26 26-26h188c14.336 0 26 11.664 26 26v366c0 14.336-11.664 26-26 26h-188c-14.336 0-26-11.664-26-26z"/>
                    <path d="m224 120h64c13.255 0 24-10.745 24-24s-10.745-24-24-24h-64c-13.255 0-24 10.745-24 24s10.745 24 24 24z"/>
                </svg>
                <span 
                    className="tag__content"
                    style={{color: color}}>
                    {type}
                </span>
            </div>
            <div className="tag__card">
                <svg 
                    className="tag__symbol tag__symbol--role" 
                    viewBox="0 0 20 20"
                    style={{fill: color}}>
                    <g stroke="none" stroke-width="1" fill-rule="evenodd">
                        <g transform="translate(-180.000000, -2159.000000)">
                            <g transform="translate(56.000000, 160.000000)">
                                <path d="M134,2008.99998 C131.783496,2008.99998 129.980955,2007.20598 129.980955,2004.99998 C129.980955,2002.79398 131.783496,2000.99998 134,2000.99998 C136.216504,2000.99998 138.019045,2002.79398 138.019045,2004.99998 C138.019045,2007.20598 136.216504,2008.99998 134,2008.99998 M137.775893,2009.67298 C139.370449,2008.39598 140.299854,2006.33098 139.958235,2004.06998 C139.561354,2001.44698 137.368965,1999.34798 134.722423,1999.04198 C131.070116,1998.61898 127.971432,2001.44898 127.971432,2004.99998 C127.971432,2006.88998 128.851603,2008.57398 130.224107,2009.67298 C126.852128,2010.93398 124.390463,2013.89498 124.004634,2017.89098 C123.948368,2018.48198 124.411563,2018.99998 125.008391,2018.99998 C125.519814,2018.99998 125.955881,2018.61598 126.001095,2018.10898 C126.404004,2013.64598 129.837274,2010.99998 134,2010.99998 C138.162726,2010.99998 141.595996,2013.64598 141.998905,2018.10898 C142.044119,2018.61598 142.480186,2018.99998 142.991609,2018.99998 C143.588437,2018.99998 144.051632,2018.48198 143.995366,2017.89098 C143.609537,2013.89498 141.147872,2010.93398 137.775893,2009.67298"></path>
                            </g>
                        </g>
                    </g>
                </svg>
                <span 
                    className="tag__content"
                    style={{color: color}}>
                    {role}
                </span>
            </div>
            <div className="tag__card">
                <svg 
                    className="tag__symbol tag__symbol--duration" 
                    viewBox="0 0 384 384"
                    style={{fill: color}}>
                    <path d="m343.59375 101.039062c-7.953125 3.847657-11.28125 13.417969-7.433594 21.367188 10.511719 21.714844 15.839844 45.121094 15.839844 69.59375 0 88.222656-71.777344 160-160 160s-160-71.777344-160-160 71.777344-160 160-160c36.558594 0 70.902344 11.9375 99.328125 34.519531 6.894531 5.503907 16.976563 4.351563 22.480469-2.566406 5.503906-6.914063 4.351562-16.984375-2.570313-22.480469-33.652343-26.746094-76-41.472656-119.238281-41.472656-105.863281 0-192 86.136719-192 192s86.136719 192 192 192 192-86.136719 192-192c0-29.335938-6.40625-57.449219-19.039062-83.527344-3.839844-7.96875-13.441407-11.289062-21.367188-7.433594zm0 0"/>
                    <path d="m192 64c-8.832031 0-16 7.167969-16 16v112c0 8.832031 7.167969 16 16 16h80c8.832031 0 16-7.167969 16-16s-7.167969-16-16-16h-64v-96c0-8.832031-7.167969-16-16-16zm0 0"/>
                </svg>
                <span 
                    className="tag__content"
                    style={{color: color}}>
                    {duration}
                </span>
            </div>
        </div>
    );
};

export default Tags;